<template>
	<CForm>
		<CRow>
			<CCol sm="12">
				<TopHeadline :headerTitle="$t('pages.warehouse.wholesaleOrderDetails')" :backTitle="$t('common.back')" :onGoBack="onGoBack">
					<template v-slot:headerRight>						
					</template>
				</TopHeadline>
				
				<Accordion :headerTitle="$t('common.generalInfo')" v-if="agentOrderDetail != null">
					<template v-slot:body>
						<CForm>
							<CRow>
								<CCol sm="6">
									<div class="row">
										<div class="col-md-4">
											<b><label>{{ $t('pages.orders.orderNumber') }}:</label></b>
										</div>
										<div class="col-md-6">
											<p><b>{{  agentOrderDetail.id }}</b></p>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<b><label>{{ $t('pages.product.numberOfProducts') }}:</label></b>
										</div>
										<div class="col-md-6">
											<p><b>{{  agentOrderDetail.numberOfProducts }}</b></p>
										</div>
									</div>									
									<div class="row">
										<div class="col-md-4">
											<b><label>{{ $t('pages.product.totalAmount') }}:</label></b>
										</div>
										<div class="col-md-6">
											<p><b><label v-text="$func.formatCurrency(agentOrderDetail.amount)" /></b></p>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<b><label>{{ $t('pages.warehouse.buyingAgent') }}:</label></b>
										</div>
										<div class="col-md-6">
											<p><b>{{  agentOrderDetail.buyingAgentName }}</b></p>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<b><label>{{ $t('common.shippingPhonenumber') }}:</label></b>
										</div>
										<div class="col-md-6">
											<p><b>{{  agentOrderDetail.buyingPhone }}</b></p>
										</div>
									</div>
									<div class="row">
										<div class="col-md-4">
											<b><label>{{ $t('common.shippingAddress') }}:</label></b>
										</div>
										<div class="col-md-6">
											<p><b>{{  agentOrderDetail.buyingAddress  }}</b></p>
										</div>
									</div>
								</CCol>
							</CRow>
							<CRow v-if="agentOrderDetail != null && agentOrderDetail.agentOrderItems != null">
								<CCol sm="12">
									<div style="padding:15px">
										<h5><label>{{ $t('pages.product.listProductInOrder') }}</label></h5>
									</div>
									<div>
										<CDataTable :items="agentOrderDetail.agentOrderItems"
													:fields="fields"
													:noItemsView="{ noItems: $t('common.nodatafound') }"
													hover
													striped
													border
													small
													fixed
													pagination
													:items-per-page="itemsPerPage"
													@pagination-change="onItemsPerPageChange"
													:item-class="itemClass"
													:items-per-page-select="{
															label : $t('common.recordsperpage'),
															values : [10,20,50,100] }">
											<template #footer>
												<tfoot>
													<tr>
														<td :colspan="8">
															<span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">
																{{agentOrderDetail.agentOrderItems == null ? 0: agentOrderDetail.agentOrderItems.length}}
															</span>
														</td>
													</tr>
												</tfoot>
											</template>

											<template #productName_text="{item}">
												<td :class="itemRowBackground(item)">
													{{ item.productName }}
												</td>
											</template>
											<template #packagingSpecification_text="{item}">
												<td :class="itemRowBackground(item)">
													{{ item.packagingSpecification }}
												</td>
											</template>
											<template #product_wholesalePrice="{item}">
												<td :class="itemRowBackground(item)">
													<label v-text="$func.formatNumber(item.price)" />
												</td>
											</template>
											<template #product_quantity="{item}">
												<td :class="itemRowBackground(item)">
													<label v-text="$func.formatNumber(item.quantity)" />
												</td>
											</template>
											<template #product_amount="{item}">
												<td :class="itemRowBackground(item)">
													<label v-text="$func.formatNumber(item.totalAmount)" />
												</td>
											</template>

											<template #debtPoints_text="{item}">
												<td :class="itemRowBackground(item)">
													<label v-text="$func.formatNumber(item.debtPoints)" />
												</td>
											</template>
											<template #totaldebtPoints_text="{item}">
												<td :class="itemRowBackground(item)">
													<label v-text="$func.formatNumber(item.totalDebtPoints)" />
												</td>
											</template>

											<template #finalAmount_test="{item}">
												<td :class="itemRowBackground(item)">
													<label v-text="$func.formatNumber(item.finalAmount)" />
												</td>
											</template>

											<template #InventoryTransfer_InOut="{item, index}">
												<td :class="itemRowBackground(item)">
													<CButton v-if="item.status <= 1" color="primary" variant="outline" size="sm"
															 @click="onDeleteWholesaleOrder(item, index)">
														{{ $t('common.delete') }}
													</CButton>
													<CButton v-if="item.status == 2" color="primary" variant="outline" square size="sm"
															 @click="onPaymentConfirmation(item, index)">
														{{ $t('common.paymentConfirmation') }}
													</CButton>
													<CButton color="primary" variant="outline" square size="sm">
														{{ $t('common.detail') }}
													</CButton>
												</td>
											</template>

										</CDataTable>
									</div>
								</CCol>
							</CRow>
						</CForm>
					</template>
				</Accordion>


				<Confirmation ref="confirmation"></Confirmation>
			</CCol>
		</CRow>			
	</CForm>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
	import i18n from '@/plugins/i18n'
    import Accordion from '@/components/Accordion.vue'  
	import Confirmation from '@/components/Confirmation.vue'
    import TopHeadline from '@/components/TopHeadline.vue'

	export default ({
        name: 'ViewOrderDetail',
		data() {
			return {
				horizontal: { label: 'col-3', input: 'col-9' },
                itemsPerPage: 10,				               
                fields: [
                    { key: 'productName_text', label: i18n.t('pages.product.productName') },
                    { key: 'packagingSpecification_text', label: i18n.t('pages.product.packagingSpecification') },
					{ key: 'product_wholesalePrice', label: i18n.t('pages.product.wholesalePrice') },                    				
					{ key: 'product_quantity', label: i18n.t('pages.product.quantity') },
					{ key: 'product_amount', label: i18n.t('common.amount') },
					{ key: 'debtPoints_text', label: i18n.t('common.debtPoints') },
                    { key: 'totaldebtPoints_text', label: i18n.t('pages.product.totalDebtPoints') },
                    { key: 'finalAmount_test', label: i18n.t('common.totalAmount') },
				]
			}
		},
        components: {			
            Accordion, Confirmation, TopHeadline
		},
		computed: {
			...mapGetters('transferInventory', ['agentOrderDetail']),
            ...mapGetters('auth', ['user']),    
			canCancelOrder() {
                if (this.user != null && this.user.roles != 'admin') {
                    if (this.agentOrderDetail != null && this.agentOrderDetail.status <= 2 && this.user.id === this.agentOrderDetail.userId.toString())
                        return true;
				}

                return false;
			},
			canRejectOrder() {
				if (this.user != null && this.user.roles === 'admin') {
                    if (this.agentOrderDetail != null && this.agentOrderDetail.status <= 2)
                        return true;
				}
                
                return false;
            }
		},
		methods: {
			...mapActions('transferInventory', ['getWholesaleOrderById']),
			// Mark Red if there is not enough quantity in stock
            itemClass(item) {
                //console.log("item.quantityInStock: ", item.quantityInStock);
                return "";
            },
            itemRowBackground(item) {               
               
                if (item.quantityInStock < 0)
                    return "btn-danger";
                
                return "py-2";
            },
            onGoBack() {
                //this.$router.push('/warehouses/transferlist');
                this.$router.go(-1);
			},           
			
            onItemsPerPageChange(item) {
                localStorage.setItem('productItemsPerPage', item)
			},		
			async onCancelOrder() { 
                this.$refs.confirmation.show(i18n.t('pages.orders.confirmCancelOrder'), async () => {                   
					var retVal = await this.$distributionService.cancelWholesaleOrder(this.$route.params.id);                    
					if (retVal == true) {
                        this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.orders.noticeCancelOrderSuccess') });
                        this.onGoBack();
					}
					else
                        this.$store.dispatch('alert/error', i18n.t('pages.orders.noticeCancelOrderFailed'), { root: true });   
                });
			},
            async onRejectDistribution(item) {
                this.$refs.confirmation.show(i18n.t('pages.warehouse.confirmReject'), async () => {
                    var retVal = await this.$store.dispatch("receivedInventory/rejectDistibutionBySeller", item.id);
                    if (retVal == true) {
                        this.onSearch();
                    }
                });
            },
		},
		mounted() {
            this.getWholesaleOrderById(this.$route.params.id);
			
            let productItemsPerPage = parseInt(localStorage.getItem('productItemsPerPage'));
            if (isNaN(productItemsPerPage))
                productItemsPerPage = 10;
            this.itemsPerPage = productItemsPerPage;
                       
		}
	})
</script>

